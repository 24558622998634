import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Grid, { GridItem } from "../components/Grid"
import SideHeaderPage from "../components/SideHeaderPage"

const MusicPage = () => {
  const data = useStaticQuery(graphql`
    query MusicPageQuery {
        allWpMusicRelease(filter: {status: {eq: "publish"}}) {
            edges {
              node {
                slug
                title
                id
                date
                releaseFormats {
                  nodes {
                    name
                    slug
                    id
                  }
                }
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
      
    }
    
  `)


    const musicLength = data.allWpMusicRelease.edges.length;

    let gridColClasses = `grid-cols-2 sm:grid-cols-3`;

    if (musicLength <= 3) gridColClasses = `grid-cols-1 sm:grid-cols-2 xl:gap-16`;


  
  return (
    <Layout>
      <Seo title="Music" />
        <SideHeaderPage pageTitle={`Releases`}>
        <Grid gridCols={gridColClasses}>
                {data.allWpMusicRelease.edges.map(music => {

                const imageData = getImage(music.node.featuredImage?.node?.localFile)

                const musicDate = music.node.date;
                const releaseYear = new Date(musicDate).getFullYear();
                let musicMeta = releaseYear;

                if (music.node.releaseFormats.nodes.length > 0) musicMeta = `${releaseYear} / `;


                if (!imageData) return null

                    return (
                        <GridItem key={music.node.id} className={`sm:pb-0 pb-10`}>
                            <Link to={`/music/${music.node.slug}`} className={`mb-3 md:mb-4 block`}>
                                <GatsbyImage 
                                    image={imageData}
                                    alt={music.node.featuredImage.node.altText || music.node.title}
                                    className={`item-shadow`}
                                />
                            </Link> 
                            <h3 className={`italic font-bold mb-2`}>
                               <Link to={`/music/${music.node.slug}`}>{music.node.title}</Link>
                            </h3>
                            <p className={`m-0 text-xs uppercase`} style={{wordSpacing: '0.5rem'}}>
                              {musicMeta}
                            {music.node.releaseFormats.nodes.slice(0,1).map(form => (
                             form.name
                            ))}  
                            </p>
                        </GridItem>
                    )
                })}
            </Grid>
        </SideHeaderPage>
      
 
    </Layout>
  )
}

export default MusicPage
